.gm-style-iw {
  @extend .text-center;
  @extend .p-3;
  max-width: 250px;

}

.k-map__wrapper {
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 550px;
  .k-map__item {
    width: 100%;
    height: 450px;
    @include media-breakpoint-up(md) {
      height: 550px;
    }
  }
  .k-map__legend {
    position: absolute;
    left: 15px;
    bottom: 15px;
    @extend .bg-white;
    @extend .box-shadow;
    z-index: 9;
    @extend .p-2;
    @extend .p-md-4;
    @include border-radius(4px);
  }
}

.k-map__marker {
  font-family: inherit;
  @extend .text-dark;
  @extend .font-weight-bold;
  @extend .position-absolute;
  &.cluster0,
  &.cluster1,
  &.cluster2,
  &.cluster3,
  &.cluster4,
  &.cluster5 {
    @include border-radius(360px);
    @extend .bg-dark;
    @extend .d-flex;
    @extend .align-items-center;
    @extend .justify-content-center;
  }
  &.cluster0 {
    font-size: 125%;
    width: 40px;
    height: 40px;
  }
  &.cluster1 {
    font-size: 150%;
    width: 60px;
    height: 60px;
  }
  &.cluster2 {
    font-size: 175%;
    width: 80px;
    height: 80px;
  }
  &.cluster3 {
    font-size: 200%;
    width: 100px;
    height: 100px;
  }
  &.cluster4 {
    font-size: 225%;
    width: 120px;
    height: 120px;
  }
  &.cluster5 {
    font-size: 250%;
    width: 140px;
    height: 140px;
  }
}