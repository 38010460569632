// =============================================================================
// Custom variables */
// =============================================================================

.box-shadow {
  -webkit-box-shadow: 0px 30px 33px -25px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 30px 33px -25px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 30px 33px -25px rgba(0, 0, 0, 0.2);
  &.no-mobile {
    box-shadow: none;
  }
}

// Structural

$navbar-dark-color: #FFF;
$navbar-dark-hover-color: $navbar-dark-color;
$navbar-dark-active-color: rgba($white, .8);
$navbar-padding-y: 0;
$navbar-padding-x: 0;
$nav-link-padding-y:1.25rem;
$navbar-nav-link-padding-x: 1.5rem;



//Layout

$card-bg: transparent;

//$dropdown-min-width:                10rem !default;
//$dropdown-padding-y:                .5rem !default;
//$dropdown-spacer:                   .125rem !default;
$dropdown-bg:                         #F7B5CA;
//$dropdown-border-color:             rgba($black, .15) !default;
//$dropdown-border-radius:            $border-radius !default;
//$dropdown-border-width:             $border-width !default;
//$dropdown-divider-bg:               $gray-200 !default;
//$dropdown-box-shadow:               0 .5rem 1rem rgba($black, .175) !default;
//
//$dropdown-link-color:               $gray-900 !default;
//$dropdown-link-hover-color:         darken($gray-900, 5%) !default;
//$dropdown-link-hover-bg:            $gray-100 !default;
//
//$dropdown-link-active-color:        $component-active-color !default;
//$dropdown-link-active-bg:           $component-active-bg !default;
//
//$dropdown-link-disabled-color:      $gray-600 !default;
//
//$dropdown-item-padding-y:           .25rem !default;
//$dropdown-item-padding-x:           1.5rem !default;
//
//$dropdown-header-color:             $gray-600 !default;