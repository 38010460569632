body {
  @extend .bg-white;
  word-wrap: break-word;
}

.k-admin__btn {
  position: fixed;
  right: 15px;
  bottom: 15px;
  @extend .btn;
  @extend .text-uppercase;
  @extend .btn-sm;
  font-size: 11px;
  background: black;
  @extend .text-white;
  letter-spacing: .43px;
  z-index: 9;
}

.k-header {
  @extend .navbar;
  @extend .navbar-light;
  @extend .bg-white;
  @extend .d-lg-flex;
  @extend .align-items-lg-center;
  @extend .justify-content-lg-end;
  //> div {
  //  @extend .d-lg-flex;
  //  @extend .align-items-lg-center;
  //  @extend .justify-content-lg-end;
  //}
  .k-header__logo {
    @extend .navbar-brand;
    @extend .m-0;
    //@extend .mr-auto;
    @extend .p-0;
    .k-header__logo__item {
      height: 100px;
    }
    //.k-navbar__logo__item {
    //  @extend .img-fluid;
    //}
  }
  .k-header__donate__btn {
    @include border-radius(360px);
    @extend .text-uppercase;
    @extend .font-weight-bold;
    @extend .small;
    @extend .bg-primary;
    @extend .text-white;
    @extend .border-0;
    @extend .cursor_pointer;
    letter-spacing: 1px;
    padding: 18px 30px;
    position: fixed;
    width: 90%;
    left: 5%;
    bottom: 10px;
    z-index: 9;
    @include media-breakpoint-up(sm) {
      position: static;
      width: auto;
      left: auto;
      bottom: auto;
      z-index: auto;
    }
  }
  .k-header__login__btn {
    @extend .btn;
    @extend .btn-light;
    @extend .text-uppercase;
    @extend .font-weight-bold;
    @extend .text-primary;
    @extend .mr-2;
    letter-spacing: .43px;
    position: fixed;
    width: 90%;
    left: 5%;
    bottom: 10px;
    z-index: 9;
    @include media-breakpoint-up(sm) {
      position: static;
      width: auto;
      left: auto;
      bottom: auto;
      z-index: auto;
    }
  }
}

.k-navbar {
  @extend .navbar;
  @extend .navbar-expand-lg;
  @extend .bg-primary;
  @extend .text-uppercase;
  @extend .font-weight-bold;
  @extend .small;
  @extend .navbar-dark;
  .k-navbar-collapse {
    @extend .navbar-collapse;
    @extend .d-lg-flex;
    @extend .align-items-lg-center;
    @extend .justify-content-lg-center;
    ul {
      @extend .text-left;
    }
    .k-navbar-nav {
      @extend .navbar-nav;
      .k-nav-item {
        @extend .px-lg-1;
        @extend .small;
        @extend .text-uppercase;
        @extend .font-weight-bold;
      }
      .k-nav-link {
        @extend .nav-link;
      }
    }
    .k-dropdown {
      @extend .dropdown;
      &:hover {
        .k-dropdown__menu {
          @extend .d-block;
        }
      }
      .k-dropdown__menu {
        @extend .dropdown-menu;
        @extend .text-secondary;
        @extend .d-none;
        @extend .text-left;
        @extend .m-0;
        @extend .font-weight-bold;
        @extend .small;
        background: lighten($info, 15%);
        .k-dropdown__item {
          @extend .dropdown-item;
          @extend .bg-transparent;
          @extend .font-weight-bold;
          @extend .small;
          color: inherit;
          &:hover {
            color: inherit;
          }
        }
      }
    }
  }
}

.k-footer {
  background: $dark;
  @extend .text-white;
  @include media-breakpoint-down(sm) {
    padding-bottom: 70px;
  }
  @include anchor {
    color: inherit;
  }
  ul {
    list-style-type: none;
    @extend .p-0;
  }
  .k-footer-top {
    @extend .py-5;
    @extend .small;
    @extend .mt-5;
    border-top: 4px solid darken($dark, 5%);
    .k-footer-top__col {
      p {
        @extend .m-0;
      }
      h4 {
        @extend .h6;
        @extend .font-weight-bold;
        @extend .mt-3;
        @extend .mb-2;
        &:first-of-type {
          @extend .mt-0;
        }
      }
      h3 {
        @extend .text-primary;
        @extend .text-uppercase;
        @extend .h6;
        @extend .font-weight-bold;
        @extend .mt-3;
        @extend .mb-2;
        &:first-of-type {
          @extend .mt-0;
        }
      }
    }
    .k-footer-top__social {
      @extend .mt-3;
      .k-footer-top__social__item {
        @extend .d-inline-block;
        @extend .pr-3;
        &:last-of-type {
          @extend .pr-0;
        }
        @include anchor {
          @extend .d-inline-block;
          height: 25px;
          img {
            height: 25px;
          }
        }
      }
    }
  }
  .k-footer-bottom {
    background: darken($dark, 5%);
    @extend .text-white;
    @extend .p-3;
    @extend .text-center;
    @extend .small;
    span {
      @extend .px-1;
    }
    @include anchor {
      color: inherit;
    }
    @include media-breakpoint-up(sm) {

    }
  }
}

hi {
  @extend .text-primary;
}

.dropdown-item {
  &,
  &:visited {
    @extend .text-dark;
    @extend .font-weight-bold;
    @extend .small;
    @extend .bg-transparent;
    @extend .text-left;
    span {
      padding-right: 15px;
    }
  }
  transition: .4s all;
  &:hover,
  &:focus {
    transition: .4s all;
    @extend .bg-white;
    padding-left: 12px !important;
    span {
      @extend .text-primary;
    }
  }
}

a.dropdown-item {
  &:hover,
  &:focus {
    @extend .text-primary;
  }
}

.dropdown-menu {
  @extend .font-weight-bold;
  @extend .small;
  @extend .text-left;
  @extend .p-0;
  padding: 1px !important;
  @extend .m-0;
  margin-top: -10px !important;
  .show {
    //top: 100% !important;
    //left: 0 !important;
    top: 10px !important;
    left: 100% !important;
    //border-top: 3px solid $primary;
  }
  > li > a {
    padding: 15px;
  }
}

.nav-item {
  > a {
    transition: .2s all;
    @include media-breakpoint-up(lg) {
      &:hover {
        @include opacity(.5);
      }
    }
  }
}

.dropdown-toggle {
  &::after {
    right: 15px;
    margin-top: 5px;
    position: absolute;
  }
}

.k-news__item__link {;
  position: relative;
  &:before,
  &:after {
    content: '';
    position: absolute;
    right: -12px;
    bottom: 4px;
    width: 6px;
    height: 6px;
    border-top: solid 1px $primary;
    border-right: solid 1px $primary;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  &:before {
    right: -16px;
  }
  &:after {
    right: -10px;
  }
}

.check-privacy {
  @extend .mb-0;
  //&:first-of-type {
  //  margin-top: 10px;
  //}
}

.k-two-column__col {
  &.k-two-column__col__bg-image {
    @include media-breakpoint-down(sm) {
      height: 400px;
    }
    height: 600px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
}



.k-button {
  @extend .d-block;
  .k-button__item {
    @extend .btn;
    @extend .btn-primary;
    @extend .text-uppercase;
    @extend .font-weight-bold;
    letter-spacing: 1px;
    white-space: normal;
  }
  .k-link__item {
    @extend .font-weight-bold;
    @extend .text-dark;
    font-style: italic;
    white-space: normal;
    position: relative;
    &:before,
    &:after {
      content: '';
      position: absolute;
      bottom: 30%;
      right: 50%;
      margin-top: -5px;
      margin-right: -5px;
      width: 10px;
      height: 10px;
      border-top: solid 2px currentColor;
      border-right: solid 2px currentColor;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
    }
    &:before {
      right: -12px;
    }
    &:after {
      right: -20px;
    }
    &.display-1 {
      &:before,
      &:after {
        border-top: solid 4px currentColor;
        border-right: solid 4px currentColor;
        margin-top: -($k-link__item__display1-arrow-size / 3);
        margin-right: -($k-link__item__display1-arrow-size / 3);
        width: $k-link__item__display1-arrow-size;
        height: $k-link__item__display1-arrow-size;
      }
      &:before {
        right: -$k-link__item__display1-arrow-size;
      }
      &:after {
        right: -($k-link__item__display1-arrow-size * 2 - 1);
      }
    }
    &.display-2 {
      &:before,
      &:after {
        border-top: solid 3px currentColor;
        border-right: solid 3px currentColor;
        margin-top: -($k-link__item__display2-arrow-size / 3);
        margin-right: -($k-link__item__display2-arrow-size / 3);
        width: $k-link__item__display2-arrow-size;
        height: $k-link__item__display2-arrow-size;
      }
      &:before {
        right: -$k-link__item__display2-arrow-size;
      }
      &:after {
        right: -($k-link__item__display2-arrow-size * 2 - 1);
      }
    }
    &.display-3 {
      &:before,
      &:after {
        border-top: solid 3px currentColor;
        border-right: solid 3px currentColor;
        margin-top: -($k-link__item__display3-arrow-size / 3);
        margin-right: -($k-link__item__display3-arrow-size / 3);
        width: $k-link__item__display3-arrow-size;
        height: $k-link__item__display3-arrow-size;
      }
      &:before {
        right: -$k-link__item__display4-arrow-size;
      }
      &:after {
        right: -($k-link__item__display4-arrow-size * 2 - 1);
      }
    }
    &.display-4 {
      &:before,
      &:after {
        border-top: solid 3px currentColor;
        border-right: solid 3px currentColor;
        margin-top: -($k-link__item__display4-arrow-size / 3);
        margin-right: -($k-link__item__display4-arrow-size / 3);
        width: $k-link__item__display4-arrow-size;
        height: $k-link__item__display4-arrow-size;
      }
      &:before {
        right: -$k-link__item__display4-arrow-size;
      }
      &:after {
        right: -($k-link__item__display4-arrow-size * 2 - 1);
      }
    }
    &.h1 {
      &:before,
      &:after {
        margin-top: -($k-link__item__h1-arrow-size / 3);
        margin-right: -($k-link__item__h1-arrow-size / 3);
        width: $k-link__item__h1-arrow-size;
        height: $k-link__item__h1-arrow-size;
      }
      &:before {
        right: -$k-link__item__h1-arrow-size;
      }
      &:after {
        right: -($k-link__item__h1-arrow-size * 2 - 1);
      }
    }
    &.h2 {
      &:before,
      &:after {
        margin-top: -($k-link__item__h2-arrow-size / 3);
        margin-right: -($k-link__item__h2-arrow-size / 3);
        width: $k-link__item__h2-arrow-size;
        height: $k-link__item__h2-arrow-size;
      }
      &:before {
        right: -$k-link__item__h2-arrow-size;
      }
      &:after {
        right: -($k-link__item__h2-arrow-size * 2 - 1);
      }
    }
    &.h3 {
      &:before,
      &:after {
        margin-top: -($k-link__item__h3-arrow-size / 3);
        margin-right: -($k-link__item__h3-arrow-size / 3);
        width: $k-link__item__h3-arrow-size;
        height: $k-link__item__h3-arrow-size;
      }
      &:before {
        right: -$k-link__item__h3-arrow-size;
      }
      &:after {
        right: -($k-link__item__h3-arrow-size * 2 - 1);
      }
    }
    &.h4 {
      &:before,
      &:after {
        margin-top: -($k-link__item__h4-arrow-size / 3);
        margin-right: -($k-link__item__h4-arrow-size / 3);
        width: $k-link__item__h4-arrow-size;
        height: $k-link__item__h4-arrow-size;
      }
      &:before {
        right: -$k-link__item__h4-arrow-size;
      }
      &:after {
        right: -($k-link__item__h4-arrow-size * 2 - 1);
      }
    }
    &.h5 {
      &:before,
      &:after {
        margin-top: -($k-link__item__h5-arrow-size / 3);
        margin-right: -($k-link__item__h5-arrow-size / 3);
        width: $k-link__item__h5-arrow-size;
        height: $k-link__item__h5-arrow-size;
      }
      &:before {
        right: -$k-link__item__h5-arrow-size;
      }
      &:after {
        right: -($k-link__item__h5-arrow-size * 2 - 1);
      }
    }
    &.h6 {
      &:before,
      &:after {
        margin-top: -($k-link__item__h6-arrow-size / 3);
        margin-right: -($k-link__item__h6-arrow-size / 3);
        width: $k-link__item__h6-arrow-size;
        height: $k-link__item__h6-arrow-size;
      }
      &:before {
        right: -$k-link__item__h6-arrow-size;
      }
      &:after {
        right: -($k-link__item__h6-arrow-size * 2 - 1);
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .display-1 {
    font-size: ($display1-size / 2);
  }

  .display-2 {
    font-size: ($display2-size / 2);
  }

  .display-3 {
    font-size: ($display3-size / 2);
  }

  .display-4 {
    font-size: ($display4-size / 2);
  }
}

.btn-race {
  @include border-radius(360px);
  @extend .text-uppercase;
  @extend .font-weight-bold;
  @extend .small;
  @extend .bg-primary;
  @extend .text-white;
  @extend .border-0;
  @extend .cursor_pointer;
  letter-spacing: 1px;
  padding: 18px 30px;
  @extend .d-inline-block;
  &.secondary {
    @extend .bg-secondary;
    @extend .text-white;
    &.outline {
      &,
      &:hover {
        @extend .bg-transparent;
        @extend .border;
        @extend .border-secondary;
        @extend .text-secondary;
      }
    }
  }
  &.light {
    @extend .bg-light;
    @extend .text-secondary;
  }
  &.btn-sm {
    padding: 9px 15px;
    font-size: ($small-font-size - 6);
  }
}
