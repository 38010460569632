@import "../../static/node_modules/bootstrap-vue/dist/bootstrap-vue";
@import "../../static/bower_components/slick-lightbox/dist/slick-lightbox";
@import "../../static/ss/libs/bootstrap-4-navbar/bootstrap-4-navbar";
//@import "../../static/js/libs/cookiebar/jquery.cookiebar";
@import "../ss/parts/structural";
@import "../ss/parts/layout";
@import "../ss/parts/carousel";
@import "../ss/parts/slider";
@import "../ss/parts/heading";
@import "../ss/parts/map";
@import "../ss/parts/step";
@import "../ss/parts/loading";
@import "../ss/parts/donate";

#instagram {
  a {
    @extend .m-0;
    @extend .d-inline-block;
  }
}

.k-boxed__fullwidth-bg {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.k-instagram {
  padding: 20px 10px;
  border: 1px solid #ecebeb;
  @include border-radius(4px);
}

p:empty {
  @extend .mb-1;
}

$input-number-size: 60px;

.input-number-group {
  display: flex;
  justify-content: center;

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    appearance: none;
  }

  .input-group-button {
    line-height: normal;
  }

  .input-number {
    width: $input-number-size;
    padding: 0 12px;
    vertical-align: top;
    text-align: center;
    outline: none;
    display: block;
    margin: 0 5px;
    border: 1px solid #ccc;
    width: 120px;
  }

  .input-number[type=number]::-webkit-inner-spin-button,
  .input-number[type=number]::-webkit-outer-spin-button {
    margin: 0;
    -webkit-appearance: none;
  }

  .input-number,
  .input-number-decrement,
  .input-number-increment {
    height: $input-number-size/2;
    user-select: none;
    border-radius: 6px;
  }

  .input-number-decrement,
  .input-number-increment {
    display: inline-block;
    width: $input-number-size/2;
    background: $dark;
    color: white;
    text-align: center;
    font-weight: 400;
    cursor: pointer;
    font-size: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.table {
  svg {
    width: 100%;
  }
  th,
  td {
    @extend .border-0;
    vertical-align: middle;
  }
}

.table-disabled {
  svg {
    cursor: not-allowed;
    g {
      path,
      rect,
      circle {
        fill: #f4f4f4;
        &.selected {
          fill: $primary;
        }
      }
    }
  }
}

.table-list {
  svg {
    width: 40px;
    height: auto;
    margin: 15px 5px;
    g {
      path,
      rect,
      circle {
        &.selected {
          fill: $primary;
        }
        fill: $primary;
      }
    }
  }
}

.table {
  svg {
    g {
      path,
      rect,
      circle {
        &.selected {
          fill: $primary;
        }
      }
    }
  }
}

.text-shadow {
  .k-button {
    a.k-link__item {
      &,
      &:hover {
        //text-shadow: 0px 0px 40px rgba(255, 255, 255, 1) !important;
        color: #fafafa !important;
      }
      &:hover {
        @include opacity(.7);
        transition: .2s all;
      }
    }
  }
}

.soldout {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, .9);
  z-index: 1;
  display: flex;
  align-items: start;
  justify-content: center;
  font-size: 26px;
  font-weight: bold;
  color: #C8277D;
  padding-top: 30%;
  text-align: center;
  @include media-breakpoint-up(md) {
    font-size: 41px;
    padding-left: 30px;
    padding-right: 30px;
  }
}

@font-face {
  font-family: 'Gotham';
  src: url('../font/Gotham/Gotham-Light.woff2') format('woff2'),
  url('../font/Gotham/Gotham-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('../font/Gotham/Gotham-Medium.woff2') format('woff2'),
  url('../font/Gotham/Gotham-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('../font/Gotham/Gotham-Book.woff2') format('woff2'),
  url('../font/Gotham/Gotham-Book.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

.k-image-box {
  @extend .p-4;
  @extend .text-center;
  //@extend .d-flex;
  //@extend .align-items-center;
  //@extend .justify-content-center;
  @extend .col-4;
  @include media-breakpoint-down(md) {
    width: 100%;
    display: block;
  }
  //@include media-breakpoint-up(md) {
  //@extend .col-md;
  //}
  @include anchor {
    @extend .d-inline-block;
  }
  .k-image-box__img {
    @extend .d-inline-block;
    @include media-breakpoint-down(md) {
      width: 100% !important;
      height: auto !important;
    }
  }
}

.k-race__hero {
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    max-height: 600px;
  }
}


.k-hero {
  overflow: hidden;
  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    max-height: 600px;
    @include media-breakpoint-up(xxl) {
      max-height: 800px;
    }
  }
  &.internal-page {
    max-height: 150px;
    @include media-breakpoint-up(xxl) {
      max-height: 800px;
    }
  }
}


.logo-wrapper {
  img {
    //max-width: 100%;
    //height: auto;
    //@include media-breakpoint-up(xl) {
    //  height: 250px;
    //  max-width: none;
    //}
    max-width: 100%;
    height: auto;
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      width: 100%;
      max-width: none;
    }
  }
}

.k-race-main-title {
  @extend .col-12;
  //@extend .px-md-6;
  @extend .m-0;
  @extend .mb-4;
  @extend .font-weight-normal;
  @extend .pt-5;
  @extend .text-uppercase;
  @extend .text-white;
  font-size: $h5-font-size;
  text-shadow: 0px 0px 100px #333333;
  @include media-breakpoint-up(xl) {
    font-size: 2.3rem;
  }
}

.k-race {
  @extend .p-3;
  @extend .w-100;
  @extend .d-inline-block;
  //@extend .text-uppercase;
  @extend .small;
  @extend .font-weight-bold;
  @extend .cursor_pointer;
  @extend .mb-4;
  @extend .mb-md-0;
  @extend .text-left;
  height: auto;
  background-color: rgba(255,255,255, .9);
  &.primary {
    @extend .bg-primary;
    @extend .text-white;
    @extend .px-xl-5;
    @extend .text-uppercase;
    @extend .d-flex;
    @extend .align-items-center;
    font-size: $h4-font-size;
    text-align: center;
    @include media-breakpoint-up(xl) {
      text-align: left;
      font-size: $h2-font-size;
    }
  }
  @include media-breakpoint-up(md) {
    height: 350px;
  }
  @include media-breakpoint-up(xl) {
    height: 400px;
  }
  .k-race__media {
    @extend .w-100;
    @extend .mr-3;
    @extend .bg-transparent;
    @extend .text-center;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      @extend .rounded;
      width: 65% !important;
      @extend .d-inline-block;
    }
  }
  .k-race__txt {
    @extend .w-100;
    //@extend .h-100;
    @extend .pt-2;
    @extend .d-flex;
    flex-wrap: wrap;
    .k-race__heading {
      @extend .w-100;
      @extend .text-center;
      .k-race__title {
        @extend .d-block;
        @extend .mb-0;
        @extend .text-primary;
        @extend .font-weight-bold;
        text-transform: uppercase;
        line-height: 1.25;
        font-size: $h4-font-size;
        @include media-breakpoint-up(xl) {
          font-size: $h2-font-size;
        }
      }
      .k-race__date {
        @extend .d-block;
        @extend .text-dark;
        @extend .h5;
        @extend .m-0;
        @extend .font-weight-bold;
      }
    }
    .k-race__wrapper-btn {
      @extend .w-100;
      @extend .text-uppercase;
      @extend .mt-2;
      @extend .mb-3;
      @extend .text-center;
      div {
        //@extend .d-block;
        .k-race__wrapper-btn__item {
          //@extend .mt-1;
          @extend .align-items-end;
        }
      }
    }
  }
}

.k-progetto {
  @extend .p-4;
  @extend .py-5;
  //@extend .box-shadow;
  @extend .w-100;
  @extend .d-inline-block;
  @extend .text-uppercase;
  @extend .small;
  @extend .font-weight-bold;
  @extend .cursor_pointer;
  @extend .mb-4;
  @extend .mb-md-0;
  &:after {
    transition: .4s all;
    content: '';
    width: 50px;
    height: 6px;
    position: absolute;
    @extend .bg-dark;
    border-radius: 360px;
    margin-left: -25px;
    left: 50%;
  }
  &.selected {
    transition: .4s all;
    @extend .text-primary;
    position: relative;
    &:after {
      @extend .bg-primary;
    }
    img {
      @include opacity(.5);
    }
  }
}

.k-progetto__donate__item {
  @extend .text-center;
  @extend .mb-5;
  &:nth-last-child(3),
  &:nth-last-child(2) {
    &:last-child {
      @extend .mb-0;
    }
  }
  &.selected {
    .k-progetto__donate__item__price {
      @extend .border-0;
      @extend .text-white;
      background: linear-gradient(120deg, $primary 0%, #b32570 100%);
      background-size: 400% 400%;
      -webkit-animation: gradient-moving 5s ease infinite;
      -moz-animation: gradient-moving 5s ease infinite;
      animation: gradient-moving 5s ease infinite;
      &:after {
        border-top: solid 2px $primary;
        border-right: solid 2px $primary;
      }
    }
  }
  .k-progetto__donate__item__price {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    width: 200px;
    height: 80px;
    @extend .mb-4;
    @extend .d-flex;
    @extend .align-items-center;
    @extend .justify-content-start;
    @extend .ml-auto;
    @extend .mr-auto;
    @extend .text-primary;
    @extend .h3;
    @include border-radius(360px);
    @extend .box-shadow;
    @extend .border;
    padding-left: 30px;
    border-color: #f4f4f4;
    cursor: pointer;
    -webkit-transition: all .5s ease;
    transition: all .5s ease;
    position: relative;
    &.k-progetto__donate__item__free {
      @extend small;
      @extend .text-uppercase;
      @extend .font-weight-bold;
      letter-spacing: .43px;
    }
    &:after {
      content: '';
      position: absolute;
      right: 37px;
      top: 50%;
      width: 12px;
      height: 12px;
      border-top: solid 2px $primary;
      border-right: solid 2px $primary;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      margin-top: -6px;
    }
    &:before {
      @extend .bg-light;
      content: '';
      position: absolute;
      right: 15px;
      top: 50%;
      width: 50px;
      height: 50px;
      //border: solid 1px $primary;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      margin-top: -25px;
      border-radius: 360px;
    }
    &:hover {
      @extend .border-0;
      @extend .text-white;
      background: linear-gradient(120deg, $primary 0%, #b32570 100%);
      background-size: 400% 400%;
      -webkit-animation: gradient-moving 5s ease infinite;
      -moz-animation: gradient-moving 5s ease infinite;
      animation: gradient-moving 5s ease infinite;
      &:after {
        border-top: solid 2px $primary;
        border-right: solid 2px $primary;
      }
    }
  }
  .k-progetto__donate__item__content {
    .k-progetto__donate__item__content__text {
      @extend .text-secondary;
      @extend .small;
      @extend .text-uppercase;
      letter-spacing: 1px;
      line-height: 150%;
      font-weight: 500;
      @extend .px-3;
      &:after {
        @extend .bg-white;
        @extend .border;
        @extend .border-light;
      }
    }
  }
  &:hover {
    .k-progetto__donate__item__content {
      .k-progetto__donate__item__content__btn {
        @extend .text-primary;
      }
    }
  }
}

.navbar {
  > .container,
  > .container-fluid {
    text-align: center;
    display: flex;
    @include media-breakpoint-up(md) {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
    }
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .k-header {
    justify-content: space-around !important;
  }
}

.k-team__avatar,
.k-team__avatar--small {
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  vertical-align: middle;
  width: 100px;
  height: 100px;
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 18px;
  color: rgb(248, 248, 248);
  font-weight: bold;
  text-transform: uppercase;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  font-family: 'Quicksand', sans-serif;
  cursor: pointer;
  @extend .text-muted;
  padding: 5px;
  border:2px solid #ccc;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 360px;
  }
}

.k-team__avatar--small {
  width: 40px;
  height: 40px;
  border:1px solid #ccc;
  img {
    width: 200%;
    height: 200%;
  }
}




.k-progress-wrapper {
  @extend .bg-light;
  @extend .p-2;
  @include border-radius(360px);
  > div {
    @include border-radius(360px);
    @extend .box-shadow;
  }
}

.bg-race {
  background-image: url(/../../static/img/bg.jpg);
  background-color: #eee !important;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  &--index {
    background-image: url(/../../static/img/bg-race-index.jpg);
    background-attachment: scroll;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    @include media-breakpoint-up(md) {
      background-position: center center;
    }
  }
}

.tooltip-inner {
  text-align: left;
}

.send-invite__wrapper {
  .a2a_svg {
    height: 18px !important;
    line-height: 18px !important;
    width: 18px !important;
    @extend .m-0;
  }
  .a2a_kit {
    @include anchor {
      @extend .p-0;
    }
  }
}

.option-privacy {
  @extend .d-inline-block;
  @include anchor {
    &.selected {
      @extend .bg-primary;
      @extend .text-white;
      @include border-radius(360px);
    }
  }
}

.k-accordion {
  @extend .w-100;
  .k-card {
    @extend .card;
    .k-card-header {
      @extend .card-header;
      border:none;
      @include border-radius(4px !important);
      @extend .mb-1;
      @extend .cursor_pointer;
      @extend .small;
      @extend .text-uppercase;
      @extend .font-weight-bold;
      color: $primary;
      letter-spacing: 1px;
      background-color: #fafafa;
      border: 1px solid #e1e1e1;
      .k-card-header--detail-btn {
        position: relative;
        width: 15px;
        height: 15px;
        float: right;
        &:before,
        &:after {
          content: '';
          position: absolute;
          width: 15px;
          height: 1px;
          background-color: $primary;
          top: 50%;
        }
        :before {
          -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
        }
        &:after {
          -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
        }
      }
    }
    .k-collapse {
      @extend .px-4;
      @extend .pt-4;
      @extend .pb-6;
      background-color: rgb(248, 248, 248);
      @extend .small;
      @extend .mb-2;
    }
  }
}

.category-wrapper {
  @extend .mb-8;
  text-align: center;
  .category-wrapper--item {
    @extend .bg-light;
    height: 400px;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 1px;
    position: relative;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    @include media-breakpoint-up(xl) {
      height: 600px;
    }
    @include anchor {
      overflow: hidden;
    }
  }
  .category-wrapper--title {
    @extend .mt-4;
    width: 100%;
    text-transform: uppercase;
    font-size: 250%;
    mix-blend-mode: difference;
    color: $primary;
    text-shadow: 0px 0px 100px #333333;
    text-align: center;
    font-weight: bold;
  }
}

.back-link {
  @extend .mb-1;
  @extend .mb-xl-0;
  a {
    position: relative;
    letter-spacing: 1px;
    text-transform: uppercase;
    display: inline-block;
    //width: 24px;
    //height: 24px;
    padding-left: 8px;
    &:before {
      content: '';
      position: absolute;
      left: 0px;
      margin-left: 0px;
      margin-top: -2.5px;
      top: 50%;
      width: 6px;
      height: 6px;
      border-top: solid 1px currentColor;
      border-right: solid 1px currentColor;
      -webkit-transform: rotate(-135deg);
      transform: rotate(-135deg);
    }
    &.btn-race {
      padding-left: 30px;
      &:before {
        left: 18px;
      }
    }
  }
}

.breadcrumb-link {
  @extend .mb-1;
  @extend .mb-xl-0;
  a {
    position: relative;
    letter-spacing: 1px;
    text-transform: uppercase;
    display: inline-block;
    //width: 24px;
    //height: 24px;
    padding-right: 10px;
    &:before {
      content: '';
      position: absolute;
      right: 0px;
      margin-left: 0px;
      margin-top: -2px;
      top: 50%;
      width: 5px;
      height: 5px;
      border-top: solid 1px currentColor;
      border-right: solid 1px currentColor;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}

.custom-select {
  background: none;
}

.features {
  @extend .pl-2;
  .features-item {
    @extend .d-inline-block;
    @extend .mb-1;
    font-size: 11px;
    @extend .text-uppercase;
    @include border-radius(360px);
    padding: 8px 12px;
    font-weight: bold;
    letter-spacing: 1px;
    border: 1px solid $secondary;
    background-color: transparent;
    color: $secondary;
    @extend .cursor_pointer;
  }
  .comb-sel {
    border: 1px solid $primary;
    background-color: transparent;
    @extend .text-primary;
  }
  .comb-dis {
    border: 1px solid rgb(204,204,204);
    background-color: rgb(204,204,204) !important;
    color: rgb(51,51,51);
    @include opacity(.3);
  }
}

.cart-number-item {
  background-color: $primary;
  color: $white;
  width:22px;
  height:22px;
  @include border-radius(360px);
  //display: inline-block;
  margin-left: -8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 10px;
  margin-top: -25px;
}

.input-number-shop {
  span {
    span {
      @extend .cursor_pointer;
      width: 20px;
      height: 20px;
      font-size: 12px;
      text-align: center;
      background-color: #ccc;
      display: inline-block;
      @include border-radius(360px);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}


input {
  &.shop {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    width: 30px;
    height: 30px;
    font-size: 12px;
    text-align: center;
    box-shadow: none;
    border: 1px solid #ccc;
    @include border-radius(360px);
    display: inline-block;
    &.squared {
      width: 50px;
      @include border-radius(6px);
    }
  }
}

.small,
small {
  letter-spacing: 1px;
}

.icon-contact {
  height: 18px;
  @include media-breakpoint-up(md) {
    height: 13px;
  }
}

.delivery_btn_wrapper {
  @extend .small;
  @extend .cursor_pointer;
  @extend .pb-2;
  @extend .bg-secondary;
  @extend .font-weight-bold;
  @extend .text-white;
  @extend .rounded;
  @extend .px-3;
  @extend .p-2;
  @extend .mb-3;
  @extend .d-flex;
  @extend .align-items-center;
  @extend .justify-content-between;
  @extend .w-100;
  .delivery_btn_wrapper__hide-show {
    position: relative;
    width: 14px;
    height: 14px;
  }
  &[aria-expanded="true"] {
    .delivery_btn_wrapper__hide-show {
      &:before {
        content: '';
        position: absolute;
        width: 14px;
        height: 2px;
        top: 50%;
        margin-top: -1px;
        background-color: currentColor;
      }
      &:after {
        content: none;
      }
    }
  }
  &[aria-expanded="false"] {
    .delivery_btn_wrapper__hide-show {
      &:before {
        content: '';
        position: absolute;
        width: 14px;
        height: 2px;
        top: 50%;
        margin-top: -1px;
        background-color: currentColor;
      }
      &:after {
        content: '';
        position: absolute;
        width: 14px;
        height: 2px;
        top: 50%;
        margin-top: -1px;
        background-color: currentColor;
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
      }
    }
  }
}

.out-of-stock {
  @extend .d-inline-block;
  @extend .mb-2;
  span {
    font-size: 10px;
    letter-spacing: 1px;
    @extend .p-2;
    @extend .d-block;
    @extend .bg-primary;
    @extend .rounded;
    @extend .text-white;
    @extend .text-uppercase;
  }
}

.present-icon {
  transform: rotate(-5grad);
}

.inapp-show {
  display: none;
  &.back-link {
    @extend .mb-1;
    @extend .mb-xl-0;
    a {
      letter-spacing: normal;
      padding-left: 14px;
      &:before {
        margin-top: -5.5px;
        width: 12px;
        height: 12px;
        border-top: solid 2px currentColor;
        border-right: solid 2px currentColor;
      }
    }
  }
}

//.modal-body {
//  max-height: calc(75vh - 10px);
//  overflow-y: auto;
//  -webkit-overflow-scrolling: touch;
//  padding: ($modal-inner-padding / 2);
//  @include media-breakpoint-up(md) {
//    max-height: calc(85vh - 10px);
//    padding: $modal-inner-padding;
//  }
//}

.info-tooltip {
  background-color: transparent;
  float: right;
  font-size: 14px;
  font-family: serif;
  margin-left: 5px;
  width: 20px;
  height: 20px;
  border: 1px solid $primary;
  padding-left: 1px;
  font-weight: bold;
  @extend .small;
  @extend .border;
  @extend .border-primary;
  @include border-radius(360px);
  @extend .d-flex;
  @extend .align-items-center;
  @extend .justify-content-center;
  @extend .text-lowercase;
}

.modal-open .modal {
  -webkit-overflow-scrolling: touch;
}

.modal-open {
  position: fixed;
  width: 100%;
}

.card-columns {
  @include media-breakpoint-up(md) {
    column-count: 3;
  }
  @include media-breakpoint-up(xl) {
    column-count: 3;
  }
}

.hide_mobile {
  @include media-breakpoint-down(md) {
    display: none;
  }
}

.sticky-top-nav {
  @include media-breakpoint-up(lg) {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 9;
  }
}


.multi-projects {
  .k-donate__item {
    margin-bottom: 0 !important;
    .k-donate__item__price {
      width: auto;
      padding-right: 30px;
      &:after,
      &:before {
        content: none;
      }
    }
    &.active {
      .k-donate__item__price {
        @extend .border-0;
        @extend .text-white;
        background: linear-gradient(120deg, $primary 0%, #b32570 100%);
        background-size: 400% 400%;
        -webkit-animation: gradient-moving 5s ease infinite;
        -moz-animation: gradient-moving 5s ease infinite;
        animation: gradient-moving 5s ease infinite;
        &:after {
          border-top: solid 2px $primary;
          border-right: solid 2px $primary;
        }
      }
    }
  }
  .multi-projects__item {
    transition: .4s all;
    @include opacity(1);
    &.active {
      @include opacity(.5);
    }
    .k-progetto {
      text-align: center;
      &:after {
        content: none;
      }
    }
    .k-progetto {
      background: #f8f8f8;
      transition: .6s all;
      &:hover {
        @include media-breakpoint-up(xl) {
          background: #f8f8f8;
          border-bottom: 10px solid #B8377B;
          border-radius: 8px;
          box-shadow: 0px 30px 33px -25px rgba(0,0,0,0.3);
        }
      }
    }
  }
  .multi-projects__btn {
    @include border-radius(360px);
    @extend .text-uppercase;
    @extend .font-weight-bold;
    @extend .small;
    @extend .bg-primary;
    @extend .text-white;
    @extend .border-0;
    @extend .cursor_pointer;
    letter-spacing: 1px;
    padding: 18px 30px;
    display: inline-block;
  }
}

.card-deck {
  @include media-breakpoint-down(md) {
    display: block;
  }
}

body.modal-open {
  overflow: visible;
}

.modal {
  overflow: scroll;
}

.generated-image-preview {
  @include border-radius(6px);
  padding: 5px;
  border: 1px solid #cccccc;
  margin-bottom: 20px;
}

.tshirt-section {
  position: relative;
  background-color: rgba(255,255,255,0.8) !important;
  color: $dark !important;
  //display: flex;
  //align-items: center;
  //justify-content: space-between;
  //padding-left: 4rem !important;
  //padding-right: 4rem !important;
  padding: 10rem 1.5rem 5rem 1.5rem !important;
  @include media-breakpoint-up(lg) {
    padding: 3rem 2rem 5rem 2rem !important;
  }
  fieldset {
    margin: 0 !important;
    box-sizing: border-box;
  }
  @include border-radius(($border-radius * 4) !important);
  .col-form-label {
    color: $primary !important;
    text-align: center;
    text-transform: uppercase;
    padding-left: 0 !important;
    padding-top: 0 !important;
    font-weight: bold !important;
    font-size: 2.3rem !important;
    @include media-breakpoint-up(lg) {
      padding-right: 10rem !important;
    }
    &:before,
    &:after {
      content: none !important;
    }
  }
  .form-control {
    &,
    &:hover,
    &:focus,
    &:visited {
      border-bottom: 1px solid $dark !important;
      color: $dark !important;
      &::-webkit-input-placeholder,
      &:-ms-input-placeholder,
      &::placeholder {
        color: red;
      }
    }
  }
  input {
    &.form-control {
      &::placeholder {
        font-size: ($small-font-size / 1.05) !important;
        color: $secondary !important;
      }
      &::-webkit-input-placeholder {
        font-size: ($small-font-size / 1.05) !important;
        color: $secondary !important;
      }
      &::-moz-placeholder {
        font-size: ($small-font-size / 1.05) !important;
        color: $secondary !important;
      }
      &:-ms-input-placeholder {
        font-size: ($small-font-size / 1.05) !important;
        color: $secondary !important;
      }
      &:-moz-placeholder {
        font-size: ($small-font-size / 1.05) !important;
        color: $secondary !important;
      }
    }
  }
  .input-group-append {
    //border-bottom: 1px solid $dark;
    .input-group-text {
      font-size: ($small-font-size / 1.15);
      color: $dark !important;
      text-transform: uppercase;
      letter-spacing: 1px;
      border-bottom: 1px solid $dark !important;
      @media screen and (max-width: 400px) {
        padding-left: 5px;
        padding-right: 5px;
      }
    }
  }
  .select-wrapper {
    position: relative;
    &:before {
      content: '';
      position: absolute;
      right: 140px;
      top: 50%;
      margin-top: -7px;
      width: 10px;
      height: 10px;
      border-top: solid 1px $dark;
      border-right: solid 1px $dark;
      -webkit-transform: rotate(135deg);
      transform: rotate(135deg);
      @media screen and (max-width: 400px) {
        right: 100px;
        margin-top: -8px;
        width: 8px;
        height: 8px;
      }
    }
    select {
      @include border-radius(0);
      -webkit-appearance: none;
      appearance: none;
    }
  }
}

.select-wrapper {
  position: relative;
  &:before {
    content: '';
    position: absolute;
    right: 5px;
    top: 50%;
    margin-top: -7px;
    width: 10px;
    height: 10px;
    border-top: solid 1px $light;
    border-right: solid 1px $light;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
    @media screen and (max-width: 400px) {
      right: 100px;
      margin-top: -8px;
      width: 8px;
      height: 8px;
    }
  }
  select {
    @include border-radius(0);
    -webkit-appearance: none;
    appearance: none;
    option {
      background: transparent !important;
    }
    &.custom-select {
      box-shadow: none;
      //color: $white;
      border-bottom: 1px solid $white !important;
    }
  }
}

@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}


//.payment_select {
//  display: flex !important;
//  align-items: flex-start;
//  justify-content: center;
//  .custom-control {
//    &.custom-radio {
//      padding-left: 0 !important;
//      .custom-control-label {
//        padding-left: 10px;
//        padding-right: 10px;
//        &:before,
//        &:after {
//          top: 0;
//          left: 0;
//          width: 100%;
//          height: 40px;
//          background-color: transparent !important;
//          margin-top: 0;
//          border: none !important;
//          @include border-radius(16px);
//          -webkit-box-shadow: inset 0px 0px 0px 2px rgba(lighten($secondary, 10%),1);
//          -moz-box-shadow: inset 0px 0px 0px 2px rgba(lighten($secondary, 10%),1);
//          box-shadow: inset 0px 0px 0px 2px rgba(lighten($secondary, 10%),1);
//        }
//      }
//      .custom-control-input {
//        &:checked {
//          ~ .custom-control-label {
//            &::after {
//              background-image: none;
//              -webkit-box-shadow: inset 0px 0px 0px 2px rgba($primary,1);
//              -moz-box-shadow: inset 0px 0px 0px 2px rgba($primary,1);
//              box-shadow: inset 0px 0px 0px 2px rgba($primary,1);
//            }
//          }
//        }
//      }
//    }
//  }
//}




.button-remove-add {
  display: flex;
  align-items: center;
  div {
    background-color: rgba($white, .9);
    color: $primary !important;
    @extend .btn;
    transition: .2s all;
    &:hover {
      @include opacity(.8);
    }
    &:first-child {
      padding-right: 12px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    &:last-child {
      padding-left: 12px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    &.disabled {
      cursor: not-allowed;
      &,
      &:hover {
        @include opacity(1);
      }
    }
  }
}

.denied-btn,
.accepted-btn {
  @extend .btn;
  background: $light !important;
  color: $secondary !important;
  font-size: $small-font-size;
  @extend .py-2;
  @extend .px-3;
  @extend .mr-2;
  text-transform: uppercase;
  @extend .btn-sm;
  border: none;
  cursor: pointer;
  letter-spacing: 1px;
  -webkit-box-shadow: 0 0 35px 1px rgba($secondary,.3);
  box-shadow: 0 0 35px 1px rgba($secondary,.3);
  transition: .4s all;
  &.selected {
    @include opacity(.4);
    -webkit-transform: scale(.98);
    -moz-transform: scale(.98);
    -ms-transform: scale(.98);
    -o-transform: scale(.98);
    transform: scale(.98);
  }
}

.accepted-btn {
  background: $primary !important;
  color: $white !important;
}


.race-item--accent {
  .k-race {
    background: $primary;
    color: $light;
    @include media-breakpoint-up(xl) {
      display: flex !important;
      align-items: center;
    }
    * {
      //background: inherit !important;
      color: inherit !important;
    }
    .k-race__wrapper-btn__item,
    .btn-race {
      background: $light !important;
      color: $primary !important;
    }
    .k-race__txt {
      .k-race__heading {
        .k-race__title {
          background: inherit !important;
          color: inherit !important;
        }
        .k-race__date {
          background: inherit !important;
          color: inherit !important;
          @include opacity(.5);
        }
      }
    }
  }
  .tshirt-section__image {
    background-color: darken($light, 10%);
    width: 150px;
    height: 150px;
    position: static;
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
    margin: 0 auto;
    @include media-breakpoint-up(xl) {
      position: absolute;
      top: auto;
      bottom: 15px;
      right: -15%;
      width: 150px;
      height: 150px;
    }
  }
}

.tshirt-section__image {
  padding-left: 2rem !important;
  -webkit-animation-name: pulse;
  animation-name: pulse;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  width: 150px;
  height: 150px;
  position: absolute;
  top: -15px;
  right: 50%;
  margin-right: -75px;
  -webkit-box-shadow: 0px 30px 33px -25px rgba(0,0,0,0.4);
  -moz-box-shadow: 0px 30px 33px -25px rgba(0,0,0,0.4);
  box-shadow: 0px 30px 33px -25px rgba(0,0,0,0.4);
  @include border-radius(360px);
  overflow: hidden;
  border: 1px solid $light;
  background-color: $primary;
  @include media-breakpoint-up(lg) {
    right: -15%;
    top: 100px;
    margin-right: 0;
    width: 200px;
    height: 200px;
  }
  img {
    width: 110%;
    height: 110%;
    object-fit: cover;
    transform: rotate(5deg) !important;
  }
}

.selected {
  background-color: $primary;
  color: $light;
  @include border-radius(360px);
}

.aaaa {
  background-color: blue;
}
