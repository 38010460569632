// =============================================================================
// Loader
// =============================================================================
.wrapper-loader {
  background-color: $loader-bg-color;
  color: $loader-fg-color;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 999999;
  .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 38px;
    height: 38px;
    margin-left: -19px;
    margin-top: -19px;
    color: inherit;
    svg {
      width: 100px;
      fill: $loader-fg-color;
    }
  }

  &.no-loader {
    display: none !important;
  }
}