#k-loader {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9;
  @extend .bg-light;
  @extend .d-flex;
  @extend .align-items-center;
  @extend .justify-content-center;
  &.k-loader__is-loaded {
    @extend .d-none;
  }
}

.k-loader__wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  @extend .d-flex;
  @extend .align-items-center;
  @extend .justify-content-center;
}

.k-loader {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
  div {
    position: absolute;
    top: 27px;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    @extend .bg-primary;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
    &:nth-child(1) {
      left: 6px;
      animation: k-loader__item1 0.6s infinite;
    }
    &:nth-child(2) {
      left: 6px;
      animation: k-loader__item2 0.6s infinite;
    }
    &:nth-child(3) {
      left: 26px;
      animation: k-loader__item2 0.6s infinite;
    }
    &:nth-child(4) {
      left: 45px;
      animation: k-loader__item3 0.6s infinite;
    }
  }
}

@keyframes k-loader__item1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes k-loader__item3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes k-loader__item2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}