$primary: #C8277D;
$info: #ed74a7;
$spacer: 1rem;
$navbar-padding-y: ($spacer * 1.5);

$badge-padding-y:                   .37em !default;
$badge-padding-x:                   .6em;

$btn-border-radius:           360px;
$btn-border-radius-lg:        $btn-border-radius;
$btn-border-radius-sm:        $btn-border-radius;

$link-hover-decoration:     none;

$font-family-serif: 'Gotham', 'Raleway', sans-serif;

$font-family-sans-serif:      $font-family-serif;
$font-family-monospace:       $font-family-sans-serif;


$btn-font-size: .8rem;
$link-hover-decoration: none;

$input-btn-padding-y-sm:         .375rem;
$input-btn-padding-x-sm:         .75rem;

$input-btn-padding-y:         .75rem;
$input-btn-padding-x:         1.5rem;

$custom-control-gutter: 1.5rem;
$custom-control-indicator-size: 1rem;

$modal-backdrop-bg: rgb(255,255,255);

$modal-backdrop-opacity: .7;

$modal-content-border-color: transparent;

$modal-inner-padding: 30px;

$modal-header-padding:$modal-inner-padding;

$close-font-weight: normal;

$modal-footer-border-color: transparent;

$modal-header-border-color: transparent;

//$custom-control-indicator-bg: #fff;
//$custom-control-indicator-checked-color: red;

$custom-control-indicator-checked-color: $primary;
$custom-control-indicator-checked-bg: $primary;



$modal-content-bg: $modal-backdrop-bg;

$warning: #ffc107;

$custom-checkbox-indicator-border-radius:360px;


$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge((
        0: 0,
        1: ($spacer * .25),
        2: ($spacer * .5),
        3: $spacer,
        4: ($spacer * 1.5),
        5: ($spacer * 3),
        6: ($spacer * 4.5),
        7: ($spacer * 6),
        8: ($spacer * 7.5),
        9: ($spacer * 9),
        10: ($spacer * 10.5)
), $spacers);

// stylelint-disable
$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;

$grays: () !default;
$grays: map-merge((
        "100": $gray-100,
        "200": $gray-200,
        "300": $gray-300,
        "400": $gray-400,
        "500": $gray-500,
        "600": $gray-600,
        "700": $gray-700,
        "800": $gray-800,
        "900": $gray-900
), $grays);

$medium-dark:          $gray-500;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1450px
);

$tooltip-padding-y: .75rem;
$tooltip-padding-x: 1.25rem;
$tooltip-color: #333333;
$tooltip-bg: #e1e1e1;
$tooltip-border-radius: 4px;

//
//// Tooltips
//
//$tooltip-font-size:           $font-size-sm !default;
//$tooltip-max-width:           200px !default;
//$tooltip-color:               $white !default;
//$tooltip-bg:                  $black !default;
//$tooltip-border-radius:        $border-radius !default;
//$tooltip-opacity:             .9 !default;
//$tooltip-padding-y:           .25rem !default;
//$tooltip-padding-x:           .5rem !default;
//$tooltip-margin:              0 !default;
//
//$tooltip-arrow-width:         .8rem !default;
//$tooltip-arrow-height:        .4rem !default;
//$tooltip-arrow-color:         $tooltip-bg !default;

$light:         $gray-200;

$headings-font-weight: bold;

$font-size-base: 1rem;
$font-size-sm: ($font-size-base * .800);
