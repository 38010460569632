@-webkit-keyframes gradient-moving {
  0% {
    background-position: 0% 50%
  }
  50% {
    background-position: 100% 50%
  }
  100% {
    background-position: 0% 50%
  }
}

@-moz-keyframes gradient-moving {
  0% {
    background-position: 0% 50%
  }
  50% {
    background-position: 100% 50%
  }
  100% {
    background-position: 0% 50%
  }
}

@keyframes gradient-moving {
  0% {
    background-position: 0% 50%
  }
  50% {
    background-position: 100% 50%
  }
  100% {
    background-position: 0% 50%
  }
}

.k-donate__item {
  @extend .text-center;
  @extend .mb-5;
  &:nth-last-child(3),
  &:nth-last-child(2) {
    &:last-child {
      @extend .mb-0;
    }
  }
  .k-donate__item__price {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    width: 250px;
    height: 80px;
    @extend .mb-4;
    @extend .d-flex;
    @extend .align-items-center;
    @extend .justify-content-start;
    @extend .ml-auto;
    @extend .mr-auto;
    @extend .text-primary;
    @extend .h1;
    @include border-radius(360px);
    @extend .box-shadow;
    @extend .border;
    padding-left: 30px;
    border-color: #f4f4f4;
    cursor: pointer;
    -webkit-transition: all .5s ease;
    transition: all .5s ease;
    position: relative;
    &.k-donate__item__free {
      @extend .h5;
      @extend .text-uppercase;
      @extend .font-weight-bold;
      letter-spacing: .43px;
    }
    &:after {
      content: '';
      position: absolute;
      right: 37px;
      top: 50%;
      width: 12px;
      height: 12px;
      border-top: solid 2px $primary;
      border-right: solid 2px $primary;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      margin-top: -6px;
    }
    &:before {
      @extend .bg-light;
      content: '';
      position: absolute;
      right: 15px;
      top: 50%;
      width: 50px;
      height: 50px;
      //border: solid 1px $primary;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      margin-top: -25px;
      border-radius: 360px;
    }
    &:hover {
      @extend .border-0;
      @extend .text-white;
      background: linear-gradient(120deg, $primary 0%, #b32570 100%);
      background-size: 400% 400%;
      -webkit-animation: gradient-moving 5s ease infinite;
      -moz-animation: gradient-moving 5s ease infinite;
      animation: gradient-moving 5s ease infinite;
      &:after {
        border-top: solid 2px $primary;
        border-right: solid 2px $primary;
      }
    }
  }
  .k-donate__item__content {
    .k-donate__item__content__text {
      @extend .text-secondary;
      font-size: 65%;
      @extend .text-uppercase;
      letter-spacing: 1px;
      line-height: 150%;
      font-weight: 500;
      @extend .px-3;
      &:after {
        @extend .border;
        @extend .border-secondary;
      }
    }
  }
  &:hover {
    .k-donate__item__content {
      .k-donate__item__content__btn {
        @extend .text-primary;
      }
    }
  }
}

@media (min-width: 768px) {
  .card-deck > .card {
    width: 33.333%;
    flex-wrap: wrap;
    flex: 0 1 auto;
  }
}

