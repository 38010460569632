/*
Made by Origami – weareorigami.com
*/
// =============================================================================
// Slick JS */
// =============================================================================

$slick-font-path: ".././bower_components/slick-carousel/slick/fonts/";
$slick-font-family: "Ionicons";
$slick-dots-font-family: "slick";
$slick-loader-path: ".././bower_components/slick-carousel/slick/";
$slick-arrow-color: $primary;
$slick-dot-color: $primary;
$slick-dot-color-active: $slick-dot-color;
$slick-prev-character: " ";
$slick-next-character: " ";
$slick-dot-character: " ";
$slick-dot-size: 40px;
$slick-opacity-default: 0.75;
$slick-opacity-on-hover: 1;
$slick-opacity-not-active: 0.25;
