// =============================================================================
// Reset */
// =============================================================================

* {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  outline: 0;
}

//.btn {
//
//  &,
//  &:active,
//  &.active {
//    background-image: inherit;
//    &:focus,
//    &.focus {
//      @include tab-focus;
//      background-image: inherit;
//    }
//  }
//
//  &:hover,
//  &:focus,
//  &.focus {
//    background-color: inherit;
//    color: inherit;
//    border-color: inherit;
//  }
//
//  &:active,
//  &.active {
//    outline: 0;
//    background-image: inherit;
//    @include box-shadow(none);
//  }
//
//}

ul,
ol {
  list-style-type: initial;
  //padding: 0;
  margin: 0;
  @include anchor {
    text-decoration: none;
    cursor: pointer;
  }
}

@include anchor {
  //color: inherit;
  text-decoration: none;
}

//
//.modal {
//  &.modal-added-item {
//    &.in {
//      display: flex !important;
//      align-items: center;
//    }
//  }
//  .modal-header {
//    margin-bottom: 30px;
//  }
//}
//
//.modal-backdrop {
//  background: rgb(255, 255, 255);
//  -webkit-backdrop-filter: saturate(180%) blur(20px);
//  backdrop-filter: saturate(180%) blur(20px);
//}

.panel {
  margin-bottom: 0;
  border: none;
  @include box-shadow(none);
}

.caret {
  border: none;
  position: relative;
  float: right;
  margin-right: 20px;
  margin-top: 10px;
  @include media-breakpoint-up(md) {
    float: none;
    margin-right: 0;
    margin-top: 0;
  }
  &:after {
    content: '';
    position: absolute;
    left: 2px;
    top: 50%;
    width: 6px;
    height: 6px;
    margin-top: -6px;
    border-top: solid 1px currentColor;
    border-right: solid 1px currentColor;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
  }
}

.form-control:focus {
  //border: none;
  box-shadow: none;
  border-color: $gray-400;
}

textarea {
  resize: none;
}

.text-inherit {
  color: inherit;
}

.text-no-transform {
  text-transform: none;
}

.opacity-0 {
  &1 {
    @include opacity(.1);
  }
  &2 {
    @include opacity(.2);
  }
  &3 {
    @include opacity(.3);
  }
  &4 {
    @include opacity(.4);
  }
  &5 {
    @include opacity(.5);
  }
  &6 {
    @include opacity(.6);
  }
  &7 {
    @include opacity(.7);
  }
  &8 {
    @include opacity(.8);
  }
  &9 {
    @include opacity(.9);
  }
}

.small {
  letter-spacing: .43px;
}

.cursor_pointer {
  cursor: pointer;
}

.cursor_not_allowed {
  cursor: not-allowed !important;
  @include opacity(.3);
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animated {
  &.infinite {
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    &.three-time {
      -webkit-animation-iteration-count: 3;
    animation-iteration-count: 3;
    }
  }
}

@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

.pulse {
  -webkit-animation-name: pulse;
  animation-name: pulse;
}

ul {
  list-style-type: initial;
}

p {
  @extend .mb-0;
}