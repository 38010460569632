.k-heading {
  @extend .text-primary;
  @extend .font-weight-bold;
  @extend .m-0;
  @extend .p-0;
  .k-heading__item-h {
    @extend .text-uppercase;
    @extend .font-weight-bold;
    @extend .border-bottom;
    @extend .border-primary;
    @extend .py-2;
    @extend .px-3;
    @extend .mb-4;
  }
  .k-heading__item-d {
    @extend .font-weight-bold;
    @extend .m-0;
    @extend .p-0;
  }
  h2 {
    @extend .h2;
  }
  h3 {
    @extend .h3;
  }
  h4 {
    @extend .h4;
  }
}