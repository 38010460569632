$k-link__item__display1-arrow-size:34px;
$k-link__item__display2-arrow-size:20px;
$k-link__item__display3-arrow-size:20px;
$k-link__item__display4-arrow-size:20px;

$k-link__item__h1-arrow-size:14px;
$k-link__item__h2-arrow-size:12px;
$k-link__item__h3-arrow-size:10px;
$k-link__item__h4-arrow-size:8px;
$k-link__item__h5-arrow-size:8px;
$k-link__item__h6-arrow-size:7px;