.k-hero {
  //background-position: center center;
  //background-repeat: no-repeat;
  //background-size: cover;
  //height: 350px;
  //overflow: hidden;
  //@extend .w-100;
  //&.k-hero__big {
  //  height: 250px;
  //  background-position: center bottom;
  //  @extend .mb-5;
  //  @include media-breakpoint-up(lg) {
  //    height: 550px;
  //  }
  //}
  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    max-height: 600px;
    @include media-breakpoint-up(lg) {
      max-height: 800px;
    }
  }
  a {
    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      max-height: 600px;
      @include media-breakpoint-up(lg) {
        max-height: 800px;
      }
    }
  }
}

.k-heading-page {
  @extend .bg-dark;
  @extend .text-white;
  @extend .py-3;
  @extend .mb-5;
  .k-heading-page__title {
    @extend .h4;
    @extend .text-uppercase;
    @extend .p-0;
    @extend .m-0;
    @extend .font-weight-bold;
  }
  .k-heading-page__subtitle {
    @extend .h4;
    @extend .text-info;
    @extend .p-0;
    @extend .m-0;
  }
}