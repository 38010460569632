.a2a_default_style {
  text-align: center;
  font-size: $share-button-social-font-size;
  a {
    &:first-of-type {
      padding-left: 0;
    }
    &:last-of-type {
      padding-right: 0;
    }
    .a2a_label {
      display: none;
    }
    &:hover {
      @include opacity(1);
    }
  }
}

.a2a_kit a {
  display: inline-block;
  padding: 10px 0;
  margin: 0;
  float: none !important;
  color: $share-button-social-fg-color;
  @include opacity(1);
  img {
    height: 20px;
  }
}

.a2a_svg {
  @extend .mx-1;
  &.a2a_s__default {
    background-color: transparent !important;
    .a2a_s_facebook,
    .a2a_s_twitter,
    .a2a_s_pinterest,
    .a2a_s_email {
      &:before {
        //background-size: 30px 30px;
      }
      svg {
        display: none;
      }
    }
  }
}

.a2a_s_twitter {
  &:before {
    content: url('../../../../static/svg/icons/social/twitter.svg');
  }
}

.a2a_s_telegram {
  &:before {
    content: url('../../../../static/svg/icons/social/telegram.svg');
  }
}

.a2a_s_google_plus {
  &:before {
    content: url('../../../../static/svg/icons/social/google_plus.svg');
  }
}

.a2a_s_sms {
  &:before {
    content: url('../../../../static/svg/icons/social/sms.svg');
  }
}

.a2a_s_skype {
  &:before {
    content: url('../../../../static/svg/icons/social/skype.svg');
  }
}

.a2a_s_facebook {
  &:before {
    content: url('../../../../../static/svg/icons/social/facebook.svg');
  }
}

.a2a_s_whatsapp {
  &:before {
    content: url('../../../../../static/svg/icons/social/whatsapp.svg');
  }
}

.a2a_s_link {
  &:before {
    content: url('../../../../../static/svg/icons/social/link.svg');
  }
}

.a2a_s_facebook_messenger {
  &:before {
    content: url('../../../../static/svg/icons/social/facebook_messenger.svg');
  }
}

.a2a_s_email {
  &:before {
    content: url('../../../../static/svg/icons/social/email.svg');
  }
}

.a2a_s_reddit {
  &:before {
    content: url('../../../../static/svg/icons/social/reddit.svg');
  }
}

.a2a_s_wechat {
  &:before {
    content: url('../../../../static/svg/icons/social/wechat.svg');
  }
}