// Made by Origami – weareorigami.com
// =============================================================================
// Slick */
// =============================================================================

.slick-slide {
  padding: 0;
}

.slick-list {
  &.draggable {
    padding: 0 !important;
  }
}

.slick-prev {
  &:before {
    content: '>';
    [dir="rtl"] & {
      content: '>';
    }
  }
}