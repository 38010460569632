.k-step-list__title {
  @extend .h5;
  @extend .text-primary;
  @extend .font-weight-bold;
  @extend .text-uppercase;
  position: relative;
  letter-spacing: .50px;
  &.text-left {
    padding-left: 60px;
    &::after {
      content: '';
      position: absolute;
      margin-left: 3px;
      margin-top: 10px;
      width: 30px;
      height: 2px;
      @extend .bg-primary;
      top: 50%;
      margin-top: -1px;
      left: 0;
      @include border-radius(360px);
      @extend .text-primary;
    }
    &::before {
      content: '';
      @extend .bg-primary;
      position: absolute;
      margin-left: 3px;
      margin-top: 10px;
      width: 16px;
      height: 16px;
      top: 50%;
      margin-top: -8px;
      left: 30px;
      border-radius: 360px;
    }
  }
  &.text-right {
    padding-right: 60px;
    &::after {
      content: '';
      position: absolute;
      margin-left: 3px;
      margin-top: 10px;
      width: 30px;
      height: 2px;
      @extend .bg-primary;
      top: 50%;
      margin-top: -1px;
      right: 0;
      @include border-radius(360px);
      @extend .text-primary;
    }
    &::before {
      content: '';
      @extend .bg-primary;
      position: absolute;
      margin-left: 3px;
      margin-top: 10px;
      width: 16px;
      height: 16px;
      top: 50%;
      margin-top: -8px;
      right: 30px;
      border-radius: 360px;
    }
  }
}

.k-step-list__subtitle {
  @extend .h6;
  @extend .text-dark;
  @extend .font-weight-bold;
  line-height: 150%;
  @extend .pl-4;
}