/*
Custom CSS/SASS | Origami – weareorigami.com
*/

// Appareance

@mixin appearance($appearance) {
  -webkit-appearance: $appearance;
  -moz-appearance: $appearance;
  -ms-appearance: $appearance;
  appearance: $appearance;
}

// Border Radius

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

// Align items

@mixin align-items($align-items) {
  -ms-align-items: $align-items;
  -webkit-align-items: $align-items;
  align-items: $align-items;
}

// Box shadow

@mixin box-shadow($box-shadow) {
  -webkit-box-shadow: $box-shadow;
  -moz-box-shadow: $box-shadow;
  box-shadow: $box-shadow;
}

// Flex grow

@mixin flex-grow($flex-grow) {
  -moz-flex-grow: $flex-grow;
  -webkit-flex-grow: $flex-grow;
  flex-grow: $flex-grow;
}

// Box direction

@mixin box-direction($box-direction) {
  -webkit-box-direction: $box-direction;
  -moz-box-direction: $box-direction;
}

// Flex direction

@mixin flex-direction($flex-direction) {
  -webkit-flex-direction: $flex-direction;
  -ms-flex-direction: $flex-direction;
  flex-direction: $flex-direction;
}

// Transform

@mixin transform($transform) {
  -ms-transform: rotate($transform); /* IE 9 */
  -webkit-transform: rotate($transform); /* Chrome, Safari, Opera */
  transform: rotate($transform);
}

// Blur effect

@mixin blur($blureffectsaturate, $blureffectblur) {
  -webkit-backdrop-filter: saturate($blureffectsaturate) blur($blureffectblur);
  backdrop-filter: saturate($blureffectsaturate) blur($blureffectblur);
}

// Anchor

@mixin anchor {
  a, a:hover, a:focus, a:active, a:visited {
    @content;
  }
}

// Button

@mixin button {
  button, a:hover, a:focus {
    @content;
  }
}

// Opacity

@mixin opacity($opacity) {
  opacity: $opacity;
  // IE8 filter
  $opacity-ie: ($opacity * 100);
  filter: alpha(opacity=$opacity-ie);
}

//  Button Variant

@mixin button-variant($color, $background, $border) {
  color: $color;
  background-color: $background;
  border-color: $border;

  &:focus,
  &.focus {
    color: $color;
    background-color: $background;
    border-color: $border;
  }
  &:hover {
    color: $color;
    background-color: $background;
    border-color: $border;
  }
}