.k-carousel {
  @extend .row;
  .slick-list {
    .slick-track {
      @extend .d-flex;
      @extend .align-items-center;
    }
  }
  .k-carousel__item {
    @extend .col;
    transition: .3s all;
    &:hover {
      cursor: pointer;
      @include media-breakpoint-up(lg) {
        @include opacity(.6);
      }
    }
    .k-carousel__item__content {
      .k-carousel__item__content__img {
        @extend .box-shadow;
        @extend .rounded-0;
        object-fit: cover;
        width: 100%;
        height: 100%;
        img {
          &:hover {
            @include opacity(1);
          }
        }
      }
      .k-carousel__item__content__title {
        //@extend .card-title;
        @extend .m-0;
        @extend .p-0;
        @extend .mt-4;
        @extend .font-weight-bold;
        @extend .text-dark;
      }
      .k-carousel__item__content__link {
        //@extend .card-link;
        @extend .text-info;
        @extend .h6;
        position: relative;
        &:before,
        &:after {
          content: '';
          position: absolute;
          right: -12px;
          bottom: 4px;
          width: 8px;
          height: 8px;
          border-top: solid 1px currentColor;
          border-right: solid 1px currentColor;
          -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
        }
        &:before {
          right: -12px;
        }
        &:after {
          right: -20px;
        }
      }
      .k-carousel__item__content__hover-text {
        @extend .position-absolute;
        @extend .w-100;
        @extend .h-100;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        //@extend .d-flex;
        //@extend .align-items-center;
        @extend .h3;
        letter-spacing: 1px;
        @extend .text-uppercase;
        @extend .px-5;
        @extend .px-md-10;
        background: -moz-radial-gradient(center, ellipse cover, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
        background: radial-gradient(ellipse at center, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#00ffffff', GradientType=1);
      }
    }
  }
}


