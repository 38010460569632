//
// Modal
// --------------------------------------------------

.modal-content {
  @include box-shadow(none);
}

//
// Close icons
// --------------------------------------------------

.close {
  @include opacity(1);
  &:hover,
  &:focus {
    color: inherit;
    @include opacity(1);
  }
}

// Button variants
//
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons

@mixin button-variant($color, $background, $border) {
  color: red;
  background-color: $background;
  border-color: $border;

  &:focus,
  &.focus {
    color: $color;
    background-color: $background;
    border-color: $border;
  }
  &:hover {
    color: $color;
    background-color: $background;
    border-color: $border;
  }
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    color: $color;
    background-color: $background;
    border-color: $border;

    &:hover,
    &:focus,
    &.focus {
      color: $color;
      background-color: $background;
      border-color: $border;
    }
  }
}

.navbar,
.navbar-toggle,
.navbar-collapse {
  border: none;
}

.navbar-toggle {
  padding: 10px;
  .icon-bar {
    height: 1px;
    border-radius: 0;
  }
  .icon-bar + .icon-bar {
    margin-top: 5px;
  }
}

//.navbar-default {
//  .navbar-collapse {
//    &.collapse {
//      &.in {
//        position: absolute;
//        top: 100px;
//        height: auto;
//        width: 100%;
//        z-index: 1;
//        background-color: $navbar-default-bg;
//      }
//    }
//    &.collapsing {
//      position: absolute;
//      top: 100px;
//      height: auto;
//      width: 100%;
//      z-index: 1;
//      background-color: $navbar-default-bg;
//    }
//  }
//}

.alert {
  margin: 0;
  border: none;
}

.dropdown-menu {
  @include box-shadow(none);
  border: none;
  border-radius: 0;
  text-align: right;
  > li > a {
    display: block;
    padding: 5px 15px;
  }
}

.pagination {
  > li {
    > a,
    > span {
      border: none;
      margin-left: 0;
    }
  }
}

.alert-dismissable,
.alert-dismissible {
  .close {
    right: 0;
  }
}

.nav-tabs {
  border: none;
  > li {
    padding-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
    > {
      @include anchor {
        border: none;
        padding: 0;
        margin: 0;
      }
    }
  }
}

.modal-header {
  //background-color: lighten($modal-content-bg, 5%);
  text-transform: uppercase;
  position: absolute;
  width: 100%;
  .close {
    position: relative;
    padding: 0;
    margin: 0;
    color: #ccc;
    font-family: Helvetica, sans-serif;
    font-weight: lighter;
    font-size: 30px;
    z-index: 999999;
    //&:before,
    //&:after {
    //  content: '';
    //  position: absolute;
    //  margin-left: -11px;
    //  margin-top: -1px;
    //  top: 50%;
    //  width: 21px;
    //  height: 1px;
    //  background-color: #ccc;
    //  -webkit-transform: rotate(-45deg);
    //  transform: rotate(-45deg);
    //}
    //&:before {
    //  -webkit-transform: rotate(-45deg);
    //  transform: rotate(-45deg);
    //}
    //&:after {
    //  -webkit-transform: rotate(45deg);
    //  transform: rotate(45deg);
    //}
  }
}



.modal-content {
  -webkit-box-shadow: 6px -4px 80px 0px rgba(0, 0, 0, 0.11);
  -moz-box-shadow: 6px -4px 80px 0px rgba(0, 0, 0, 0.11);
  box-shadow: 6px -4px 80px 0px rgba(0, 0, 0, 0.11);
}

.modal-footer {
  //background-color: darken($modal-content-bg, 2%);
  text-transform: uppercase;
}

.modal-header {
  letter-spacing: 1px;
}

.modal-footer {
  padding: ($modal-inner-padding / 2);
}

.custom-select {
  -moz-appearance: none;
}

.tooltip {
  text-transform: none;
  letter-spacing: .43px;
  font-weight: normal;
}

.custom-control-label {
  cursor: pointer;
  &.center {
    &::before {
      left: 50%;
      width: $custom-control-indicator-size;
      margin-left: -($custom-control-indicator-size / 2);
    }

    // Foreground (icon)
    &::after {
      left: 50%;
      width: $custom-control-indicator-size;
      margin-left: -($custom-control-indicator-size / 2);
    }
  }
}

label {
  cursor: pointer;
}

.custom-checkbox {

  .custom-control-input:checked ~ .custom-control-label {
    &::after {
      @extend .bg-primary;
      @extend .border-0;
      background-image: $custom-checkbox-indicator-icon-checked;
    }
  }
}

.custom-control-label {
  &::before,
  &::after {
    @include border-radius(360px);
    @extend .bg-light;
    @extend .text-uppercase;
    @extend .small;
    @extend .border;
    @extend .border-secondary;
    top: 50%;
    margin-top: -(($custom-control-indicator-size) / 2);
  }
  p {
    @extend .m-0;
  }
}

.card {
  @extend .border-0;
  &.list {
    @extend .border-0;
    @extend .mb-2;
    .card-header {
      @extend .border-0;
      @extend .p-4;
      @extend .rounded;
    }
  }
}

.form-control:disabled,
.form-control[readonly] {
  cursor: not-allowed;
}

//
//.list-group {
//  height: 58px;
//  white-space: nowrap;
//  overflow-y: hidden;
//  overflow-x: scroll;
//  .list-group-item {
//    display: inline-block;
//    text-transform: none;
//    transition: .6s;
//    height: 45px;
//    position: relative;
//    width: 160px;
//    color: white;
//  }
//}

.navbar {
  > .container,
  > .container-fluid {
    text-align: center;
    display: block;
    @include media-breakpoint-up(md) {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
    }
  }
}

.custom-control {
  @extend .text-uppercase;
  @extend .small;
  letter-spacing: 1px;
  @extend .font-weight-bold;
}

//
//.custom-radio {
//  .custom-control-input:checked ~ .custom-control-label {
//    &::before {
//      background-color: $primary;
//    }
//    &::after {
//      background-image: $custom-radio-indicator-icon-checked;
//    }
//  }
//}






.input-group span.input-group-btn,.input-group input,.input-group button{
  display: block;
  width: 100%;
  border-radius: 0;
  margin: 0;
}
.input-group {
  position: relative;
}
.input-group span.data-up{
  position: absolute;
  top: 0;
}
.input-group span.data-dwn{
  position: absolute;
  bottom: 0;
}
.form-control.text-center {
  margin: 34px 0;
}
.input-group-btn:last-child>.btn, .input-group-btn:last-child>.btn-group{
  margin-left:0;
}

.table {
  svg {
    path {
      &.chair {
        fill: red;
      }
    }
  }

}