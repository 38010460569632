.k-slider {
  @extend .row;
  @extend .m-0;
  .k-slider__item {
    @extend .col;
    .k-slider__item__content {
      @extend .position-relative;
      .k-slider__item__content__img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        @extend .img-fluid;
        img {
          &:hover {
            @include opacity(1);
          }
        }
      }
      .k-slider__item__content__title {
        @extend .m-0;
        @extend .p-0;
        @extend .mt-4;
        @extend .font-weight-bold;
        @extend .text-dark;
        @extend .h5;
      }
      .k-slider__item__content__link {
        @extend .text-info;
        @extend .h6;
        @extend .mt-2;
        position: relative;
        &:before,
        &:after {
          content: '';
          position: absolute;
          right: -12px;
          bottom: 4px;
          width: 8px;
          height: 8px;
          border-top: solid 1px currentColor;
          border-right: solid 1px currentColor;
          -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
        }
        &:before {
          right: -12px;
        }
        &:after {
          right: -20px;
        }
      }
      .k-slider__item__content__hover-text {
        @extend .position-absolute;
        @extend .w-100;
        @extend .h-100;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        @extend .d-flex;
        @extend .align-items-center;
        @extend .h3;
        letter-spacing: 1px;
        @extend .text-uppercase;
        @extend .px-5;
        @extend .px-md-10;
        &.text-left {
          background: -moz-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%); /* FF3.6-15 */
          background: -webkit-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%); /* Chrome10-25,Safari5.1-6 */
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#00ffffff', GradientType=1); /* IE6-9 */
        }
        &.text-right {
          background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%); /* FF3.6-15 */
          background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%); /* Chrome10-25,Safari5.1-6 */
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=1); /* IE6-9 */
        }
        &.text-center {
          background: -moz-radial-gradient(center, ellipse cover, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
          background: radial-gradient(ellipse at center, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#00ffffff', GradientType=1);
        }
        @include media-breakpoint-up(lg) {
          > div {
            width: 50%;
          }
        }
      }
    }
  }
}