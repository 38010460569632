// =====================================================================================================================
// Variabili di default
// =====================================================================================================================

$cookiebar-bg-color: rgba(0, 0, 0, .7) !default;
$cookiebar-min-height: 24px !default;
$cookiebar-line-height: 24px !default;
$cookiebar-color: #EEE !default;
$cookiebar-text-align: center !default;
$cookiebar-padding: 20px !default;
$cookiebar-z-index: 10000 !default;

$cookiebar-link-color: #fff !default;
$cookiebar-link-border: none !default;
$cookiebar-link-margin-left: 8px !default;
$cookiebar-link-padding: 0 6px !default;
$cookiebar-link-border-radius: 3px !default;

$cookiebar-link-enable-background: #C8277D !default;
$cookiebar-link-enable-background-hover: #C8277D !default;
$cookiebar-link-enable-color: #FFF !default;
$cookiebar-link-enable-color-hover: #FFF !default;
$cookiebar-link-enable-border: none !default;
$cookiebar-link-enable-border-hover: none !default;

$cookiebar-link-disable-background: #990000 !default;
$cookiebar-link-disable-background-hover: #bb0000 !default;
$cookiebar-link-disable-color: #FFF !default;
$cookiebar-link-disable-color-hover: #FFF !default;
$cookiebar-link-disable-border: none !default;
$cookiebar-link-disable-border-hover: none !default;

$cookiebar-link-policy-background: #fafafa !default;
$cookiebar-link-policy-background-hover: $cookiebar-link-policy-background !default;
$cookiebar-link-policy-color: rgba(0, 0, 0, .7) !default;
$cookiebar-link-policy-color-hover: rgba(0, 0, 0, .7) !default;
$cookiebar-link-policy-border: none !default;
$cookiebar-link-policy-border-hover: none !default;

#cookie-bar {
  background: $cookiebar-bg-color;
  height: auto;
  min-height: $cookiebar-min-height;
  line-height: $cookiebar-line-height;
  color: $cookiebar-color;
  text-align: $cookiebar-text-align;
  padding: $cookiebar-padding;
  z-index: $cookiebar-z-index;
  font-size: 12px;
  letter-spacing: 1px;
  &.fixed {
    position: fixed;
    bottom: 0 !important;
    top: auto;
    left: 0;
    width: 100%;
    &.bottom {
      bottom: 0;
      top: auto;
    }
  }

  p {
    margin: 0;
    padding: 0;
    letter-spacing: 1px;
    line-height: 150%;

    span {
      display: block;
      @include media-breakpoint-up(md) {
        display: inline-block;
      }
    }
  }

  a {
    color: $cookiebar-link-color;
    display: inline-block;
    border: $cookiebar-link-border;
    border-radius: $cookiebar-link-border-radius;
    text-decoration: none;
    padding: $cookiebar-link-padding;
    margin-left: $cookiebar-link-margin-left;
  }

  .cb-enable,
  .cb-disable,
  .cb-policy {
    @extend .text-uppercase;
    @include border-radius(360px);
    @extend .p-1;
    @extend .px-3;
    @extend .font-weight-bold;
    @extend .mt-1;
  }

  .cb-enable {

    background: $cookiebar-link-enable-background;
    color: $cookiebar-link-enable-color;
    margin-top: 5px;
    @include media-breakpoint-up(md) {
      margin-top: 0;
    }
    &:hover {
      background: $cookiebar-link-enable-background-hover;
      color: $cookiebar-link-enable-color-hover;
    }
  }

  .cb-disable {
    background: $cookiebar-link-disable-background;
    color: $cookiebar-link-disable-color;
    border: $cookiebar-link-disable-border;
    margin-top: 5px;
    @include media-breakpoint-up(md) {
      margin-top: 0;
    }
    &:hover {
      background: $cookiebar-link-disable-background-hover;
      color: $cookiebar-link-disable-color-hover;
      border: $cookiebar-link-disable-border-hover;
    }
  }

  .cb-policy {
    background: $cookiebar-link-policy-background;
    color: $cookiebar-link-policy-color;
    border: $cookiebar-link-policy-border;

    &:hover {
      background: $cookiebar-link-policy-background-hover;
      color: $cookiebar-link-policy-color-hover;
      border: $cookiebar-link-policy-border-hover;
    }
  }
}

